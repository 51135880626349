import React from 'react'
import './logo.css'
function Logo() {

    const handleToggleSideBar = () => {
        document.body.classList.toggle('toggle-sidebar');
    }
  return (
    <div className="d-flex align-items-center justify-content-between">
        <a href="/" className='logo d-flex align-items-center'> 
          {/* <!-- Logo --> */}
          <img src="/images/logo_ins.jpeg" alt="Logo INS" width="250" height="250" className="d-inline-block align-text-top"/>
          {/* <span className=" d-lg-block ">ANStat</span> */}
        </a>

        <i 
        className="bi bi-list toggle-sidebar-btn" 
        onClick={handleToggleSideBar}
        ></i>
      
    </div>
  )
}

export default Logo
