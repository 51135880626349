// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// // Import Icons 
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'remixicon/fonts/remixicon.css';

// // Import Bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// import './App.css';
// import Header from './components/Header';
// import SideBar from './components/SideBar';
// import Main from './components/Main';

// const App = () => {
//   return(
//     <>
//       <Header />
//       <SideBar />
//       <Main />
//     </>
//   )

//   ;
// };

// export default App;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import Icons
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';

// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './App.css';
import Header from './components/Header';
import SideBar from './components/SideBar';
import Main from './components/Main';

const App = () => {
  const [selectedIndicateur, setSelectedIndicateur] = useState(null);
  const [indicateurInfo, setIndicateurInfo] = useState(null);
  const [valeurs, setValeurs] = useState([]);

  // Function to handle indicator selection
  const handleIndicateurSelect = (id) => {
    setSelectedIndicateur(id);
  };

  // Fetch indicator details and values
  useEffect(() => {
    if (selectedIndicateur) {
      // Fetch indicator details
      axios.get(`https://indicateurapi.stats.ci/indicateurs/${selectedIndicateur}`)
        .then(response => {
          setIndicateurInfo(response.data);
        })
        .catch(error => {
          console.error('Error fetching indicator details:', error);
        });

      // Fetch indicator values
      axios.get(`https://indicateurapi.stats.ci/valeurs/${selectedIndicateur}`)
        .then(response => {
          setValeurs(response.data);
        })
        .catch(error => {
          console.error('Error fetching indicator values:', error);
        });
    }
  }, [selectedIndicateur]);

  return (
    <Router>
      <Header />
      <SideBar onIndicateurSelect={handleIndicateurSelect} />
      <Main indicateurInfo={indicateurInfo} valeurs={valeurs} />
    </Router>
  );
};

export default App;
