// import React from 'react'
// import './pagetitle.css'
// function PageTitle({categorie}) {
//   return (
//     <div className='pagetitle'>
//         <h1>SUIVI DES INDICATEURS INTERNATIONAUX </h1>
//         <nav>
//             <ol className='breadcrumb'>
//                 <li className="breadcrumb-item">
//                     <a href="">
//                         <i className="bi bi-house-door"></i>
//                     </a>
//                 </li>
//                 <li className="breadcrumb-item active">{categorie}</li>
//             </ol>
//         </nav>
//     </div>
//   )
// }

// export default PageTitle

import React from 'react';
import './pagetitle.css';

function PageTitle({ categorie }) {
  return (
    <div className='pagetitle'>
      <h1>SUIVI DES INDICATEURS INTERNATIONAUX</h1>
      <nav>
        <ol className='breadcrumb'>
          <li className="breadcrumb-item">
            <a href="#">
              <i className="bi bi-house-door"></i>
            </a>
          </li>
          <li className="breadcrumb-item active">{categorie}</li>
        </ol>
      </nav>
    </div>
  );
}

export default PageTitle;
