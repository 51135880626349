import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '100%',
  margin: '10px',
  position: 'relative', // Ajout de position relative pour la carte // Ajout de position relative pour la carte
  // background: 'linear-gradient(to bottom, #f08c00, #ffffff)' // Dégradé de bleu à blanc
  backgroundColor: 'white',
  border : '2px solid green',
  cursor : 'pointer',
};

const cardBodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px', // Définir une hauteur fixe pour la carte
  position: 'relative', // Position relative pour permettre le positionnement absolu des enfants
};

const cardTitleStyle = {
  fontSize: '1.2rem',
  margin: '0.5rem 0',
  fontWeight: 'bold',
  color: '#2E86C1',
  zIndex: 1, // Assurez-vous que le titre soit au-dessus de l'icône
};

const cardTextStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#008080',
  zIndex: 1, // Assurez-vous que le texte soit au-dessus de l'icône
};

const iconStyle = {
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  fontSize: '3rem',
  // color: '#2E86C1',
  opacity: 0.5, // Ajouter un peu de transparence pour l'icône
  zIndex: 0, // Assurez-vous que l'icône soit en dessous du texte
};



const Indicateurs = ({ title, value, icon,iconcolor,double,taille }) => {
  let Mycolor = iconcolor;
  return (
    <div className={`${150} mb-2`}>
      <div className="card  text-black" style={cardStyle}>
        <div className="card-body text-center" style={cardBodyStyle}>
          <h5 className="card-title" style={cardTitleStyle}>{title}</h5>
          <p className="card-text" style={cardTextStyle}>{value}</p>
          <i className={`fas ${icon}`} style={{...iconStyle,color:Mycolor}}></i> 
          {/* {double && <i className={`fas ${double}`} style={{...iconStyle,color:"pink",marginLeft:'25px'}}></i>}  */}
        </div>
      </div>
    </div>
  );
};
export default Indicateurs;
