import React from 'react'
import './header.css'
import Logo from './Logo'
import SearchBar from './SearchBar'
import Nav from './Nav'
function Header() {
  return (
   <header id="header" className="header d-flex align-items-center fixed-top">
       {/* <!-- Logo and Title --> */}
        <Logo />
       {/* <!-- Search bar on the right --> */}

       {/* <SearchBar className="ms-auto" /> */}
       {/* <!-- Nav  --> */}
       {/* <Nav /> */}
   </header>
  )
}

export default Header
