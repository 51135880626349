import React from 'react';
import Chart from 'react-apexcharts';

function PopChart({ series }) {
  const options = {
    chart: {
      background: '#f4f4f4',
      foreColor: '#333',
    },
    xaxis: {
      categories: series[0]?.data.map((_, index) => (2014 + index).toString()),
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    fill: {
      colors: ['#FEB019']
    },
    dataLabels: {
      enabled: true
    },
    legend: {
      show: false
    },
    title: {
      text: 'Evolution sur 10 ans',
      align: 'center',
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: '25px'
      }
    }
  };

  return (
    <>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="350"
      />
    </>
  );
}

export default PopChart;

