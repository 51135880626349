// import React, { Component } from 'react';
// import Chart from 'react-apexcharts';

// class CharPie extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             options: {
//                 chart: {
//                     background: '#f4f4f4',
//                     foreColor: '#333',
//               },
//               labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
//               responsive: [{
//                 breakpoint: 480,
//                 options: {
//                   chart: {
//                     width: 200
//                   },
//                   legend: {
//                     position: 'bottom'
//                   }
//                 }
//               }]
//             },
//             series: [44, 55, 13, 43, 22],
//         };
//     }
//     onClick = () => {
//         this.setState({
//             options: {...this.state.options,
//                 plotOptions: {
//                     ...this.state.options.plotOptions,
//                     bar: {
//                         ...this.state.options.plotOptions.bar,
//                         horizontal: !this.state.options.plotOptions.bar.horizontal
//                     }
//                 }
//             }
//         })
//     }
//     render() {
//         const options = this.props.options || this.state.options;
//         const series = this.props.series || this.state.series;
//         return (
//             <>
//             <Chart 
//                 options={options}
//                 series={series}
//                 type="pie"
//                 width="100%"
//                 height="350"
//             />
//             {/* <button  onClick={this.onClick} >VERTICAL</button> */}
//             </>
//         );
//     }
// }

// export default CharPie;

import React from 'react';
import Chart from 'react-apexcharts';

function CharPie({ series, options }) {
  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      width="100%"
      height="350"
    />
  );
}

export default CharPie;

