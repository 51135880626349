// import React from 'react'

// function Onglets() {
//   return (
//     <div>
//       <ul className="nav nav-tabs" id="myTab" role="tablist">
//   <li className="nav-item" role="presentation">
//     <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Producteur de l'indicateur</button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Définitions</button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Méthode de cacul</button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="contact-tab2" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false">Données en entrées</button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="contact-tab3" data-bs-toggle="tab" data-bs-target="#contact3" type="button" role="tab" aria-controls="contact3" aria-selected="false">Sources de données </button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="contact-tab4" data-bs-toggle="tab" data-bs-target="#contact4" type="button" role="tab" aria-controls="contact4" aria-selected="false">Interprètation</button>
//   </li>
//   <li className="nav-item" role="presentation">
//     <button className="nav-link" id="contact-tab5" data-bs-toggle="tab" data-bs-target="#contact5" type="button" role="tab" aria-controls="contact5" aria-selected="false">Rang</button>
//   </li>
// </ul>
// <div className="tab-content" id="myTabContent">
//   <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">...</div>
//   <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
//   <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
//   <div className="tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
//   Earum optio eos quibusdam, quae provident, harum accusantium temporibus, doloribus assumenda error velit incidunt explicabo consequatur quos necessitatibus!
//   At facilis iste dolorem nostrum exercitationem sit incidunt totam inventore qui ipsum dolore odio dolores tempora est, impedit harum, nesciunt vitae quis mollitia possimus! Illo sapiente quaerat consequuntur in ducimus.
//   Eos voluptates pariatur odit ea magni fugit officia amet alias quo labore qui, consequatur necessitatibus at iusto voluptas, ex, consectetur corrupti error debitis voluptatibus mollitia sit nulla. Possimus, perspiciatis eaque.
//   Quos natus quisquam dolor tenetur possimus necessitatibus neque voluptate explicabo nam debitis, asperiores hic minima non maiores laudantium! Molestiae corporis necessitatibus quas corrupti totam nam a. Mollitia voluptatibus necessitatibus eveniet?
//   Officiis provident dolor dolorum incidunt tempora eius odit mollitia! Expedita cumque, maiores illo quae mollitia officiis dolorem nam ullam. Explicabo debitis et neque vel! Est asperiores praesentium fugit temporibus amet?
//   Impedit tempore a ipsa pariatur alias natus dignissimos harum officia atque est sunt amet sint laboriosam voluptate libero quidem earum inventore, incidunt illo consectetur vel! Debitis fuga magni veniam quod.
//   Reprehenderit voluptate fugiat laboriosam consectetur velit, possimus nisi dicta itaque iusto omnis voluptates alias voluptatum ab vitae facilis id? Quisquam qui amet eum, voluptas fugit iusto suscipit. Adipisci, quidem nam.
//   Suscipit ullam sint tenetur, harum consequuntur, laboriosam praesentium dicta explicabo fugit veritatis libero illo dolor, quam sunt iure nam facilis quia itaque perspiciatis aut odit consequatur. Soluta aperiam delectus quia!
//   Nostrum, neque obcaecati a architecto deserunt voluptatibus asperiores veritatis est repudiandae voluptate, quasi quas quibusdam et minima dolor corporis? Aliquid aperiam inventore suscipit eum unde, quod et perspiciatis doloremque voluptatum!
//   Repellendus laudantium rem cupiditate soluta nemo illo laborum, dolor culpa neque aliquam animi sequi officiis odio earum temporibus veritatis necessitatibus ipsum quasi suscipit velit, ad sunt excepturi perspiciatis. Veniam, optio.
//   Veritatis, animi hic amet vero officiis dignissimos saepe eius nobis deleniti corporis sed vel omnis enim minus non facere impedit ducimus! Ipsum consequuntur animi repellat perspiciatis nostrum eos voluptatibus dignissimos?
//   Recusandae inventore tempore dolore quae doloribus eligendi quasi explicabo a blanditiis. Odit illo quas distinctio harum eligendi quam eaque vero, doloribus natus recusandae enim id obcaecati sequi. At, numquam cum!
//   Commodi dignissimos excepturi magni alias nam quam facilis eaque officiis consequatur dicta atque saepe, laboriosam, esse laborum minus quae qui veritatis perspiciatis. Consequuntur, alias! Dolorum error voluptatum obcaecati laborum dolores.
//   Quae praesentium vitae, consequuntur rerum aliquam dicta soluta unde illum natus dolores ipsa reiciendis saepe atque ad minima. Dolor ipsam vero similique explicabo, ea magnam officiis molestiae nemo fuga reprehenderit!
//   Autem tenetur reprehenderit optio facere illo suscipit, corporis ipsum, voluptates cumque obcaecati mollitia in et dolorem error aspernatur possimus, laborum harum quasi praesentium molestiae. Assumenda saepe magnam in consequatur ea.
//   Nesciunt doloribus qui odit dolore quis molestias, distinctio possimus. Ea, hic cumque eius repudiandae doloribus sint veritatis! Nam temporibus mollitia, impedit fuga, vel illum delectus magni eos et amet blanditiis.
//   Eveniet eligendi voluptate accusamus quas dicta perferendis? Alias nisi placeat aut laborum aperiam eos molestias voluptatibus distinctio et illo, in repudiandae officiis provident ab harum architecto quae a. Aut, officia!
//   Maxime quos, inventore, saepe iste velit unde incidunt earum debitis excepturi ipsum rem alias nostrum numquam eaque omnis quas tempore laboriosam natus nisi libero. Unde minima vel optio nostrum animi.
//   Doloribus reiciendis amet odio voluptatibus quam eos soluta eius libero delectus quibusdam omnis repudiandae iusto vel molestiae quod ipsa incidunt, quas quia eveniet magni hic rem. Id natus vitae voluptatem.
//   Exercitationem ducimus architecto reprehenderit dolores laborum sint possimus quibusdam in nemo, nobis, rerum dignissimos illum corporis odit neque? Maiores obcaecati consequatur delectus architecto repudiandae. Modi expedita aperiam velit vero. Fugiat?
//   Totam veritatis nam sit corporis nobis ipsa quo inventore quod deserunt tempora cum cumque odit consequatur aliquam a recusandae, non perspiciatis dolore nemo. Cupiditate iste est vitae eos quisquam vel!
//   Magni saepe ea tempora suscipit voluptatum possimus quas impedit ab ratione soluta. Odit aliquam inventore eius aperiam ut! Labore fugiat sed doloremque ad expedita id quibusdam magni quos qui sapiente.
//   Reiciendis recusandae praesentium error voluptas eius amet officiis ullam temporibus, tempora optio iure repellat deserunt et reprehenderit suscipit placeat dignissimos sequi autem sapiente inventore cumque dolore nesciunt at? Minus, error.
//   Blanditiis cupiditate a cumque, ea ullam eligendi tempora debitis temporibus laborum asperiores maiores voluptatibus corrupti dolor nemo, expedita ex incidunt odio error. Sunt numquam rem omnis quod ut? Placeat, dicta!
//   Rem deserunt, magnam ex hic quam minima aspernatur ad accusantium, distinctio sapiente explicabo dignissimos? Amet recusandae neque mollitia non vitae consequuntur exercitationem placeat distinctio laudantium quo hic sed, deleniti harum.
//   Odio optio quidem labore numquam, provident odit voluptates, dolore ab possimus accusantium quibusdam ipsa laboriosam quos saepe quae at est dignissimos pariatur reiciendis, blanditiis eligendi ex quis earum? Eos, illo.
//   Id placeat alias voluptatibus blanditiis, necessitatibus officia perferendis sed quod possimus corrupti cupiditate mollitia dolores autem hic tempore, magnam rem eum. Molestiae in aspernatur ratione eveniet aliquid placeat culpa. Neque?
//   Minus ducimus et obcaecati commodi porro iure hic perspiciatis consectetur rem assumenda, reprehenderit itaque soluta, maxime pariatur voluptate nam. Fugit facere placeat asperiores cumque suscipit doloremque deleniti vel doloribus dolor.
//   Nam molestiae minus ut voluptate recusandae architecto cupiditate, voluptatibus doloremque suscipit nesciunt a ipsa placeat? Sunt fugit iure deserunt, ullam sequi id ratione quasi eius necessitatibus beatae non. Sunt, perferendis.
//   Quas debitis quibusdam consectetur quam, facilis saepe provident ad nulla, doloremque praesentium odio modi quis suscipit iste nobis excepturi, aut incidunt ab libero explicabo tempora! Illo est aut ducimus ea?
//   Modi optio, sint veritatis eveniet iste recusandae aut possimus dolores doloremque magni explicabo nemo ipsa eos culpa quia impedit dolorum hic illum numquam fugiat libero quos non reprehenderit nesciunt. Deleniti!
//   Similique minus quia exercitationem in blanditiis numquam qui recusandae perspiciatis praesentium distinctio quisquam incidunt accusamus atque laudantium facere, sunt nulla culpa corrupti, illo aut officiis minima quibusdam. Accusantium, perferendis dolore.
//   Voluptatibus voluptatum ipsa pariatur voluptas obcaecati sint, harum consequatur perferendis deserunt totam optio quaerat iusto, minus dolor dignissimos expedita aspernatur saepe neque distinctio. Ipsam nobis mollitia harum delectus fugit exercitationem!
//   Laboriosam, rem sint asperiores maiores commodi hic nisi dicta laborum expedita repellat ut quam nostrum autem nihil cum rerum quisquam architecto perspiciatis quia assumenda consequuntur deleniti illo eum? Amet, odit!
//   Alias id neque magnam consectetur, dicta laudantium maxime cupiditate numquam similique. Incidunt earum, ea magni perferendis eius tenetur maiores quos repellendus ut animi, temporibus aperiam obcaecati pariatur tempore rem eum!
//   Facilis amet corrupti cupiditate quis corporis reprehenderit eveniet eum nostrum ducimus blanditiis, omnis distinctio ut perferendis non nulla et officia quas quasi, velit id nobis nihil inventore molestias! Amet, repudiandae?
//   Vero at, rem iure aut eum libero qui, quis unde suscipit dolore cupiditate atque voluptas ipsam excepturi voluptates odit sed est fugiat numquam a inventore. Velit, labore. Deserunt, error temporibus!
//   Consectetur possimus tenetur iste debitis similique harum ratione aut. Ullam sit obcaecati culpa, dolorem nam perferendis? Laborum ut expedita doloribus delectus suscipit soluta odit nobis excepturi in magni, consequatur debitis.
//   Non fuga molestiae magni corporis iusto unde, totam, commodi obcaecati provident, recusandae perferendis fugiat dicta magnam eius corrupti debitis accusantium? Praesentium mollitia delectus blanditiis nisi ullam enim quidem repudiandae dicta.
//   Recusandae minima quasi ducimus dicta nisi natus ex rerum officiis corporis distinctio nam nostrum, ad unde eos quam blanditiis accusantium at nemo voluptatum, sunt mollitia nihil similique magnam molestias! Tempore!
//   Dolorum hic dolore officia corporis accusamus? Fugiat rem optio esse vitae voluptates odit repellat repellendus neque incidunt ullam, cum a delectus maxime quidem ipsa eveniet voluptatibus porro cupiditate beatae. Maiores?
//   Dolores ab consequatur quae doloribus voluptatem accusantium pariatur. Consequatur esse expedita accusantium eos incidunt nobis reprehenderit dolore quos ex. Rem ipsa nesciunt ullam aliquam quisquam tenetur eligendi dolores cumque delectus!
//   Aliquid facilis est, maiores ea enim porro. Aliquam, architecto repellat dignissimos velit fugiat, vel doloribus tempora suscipit quis ducimus labore. Non magnam velit, facere quaerat molestiae iure temporibus exercitationem quibusdam!
//   Iure culpa reprehenderit sapiente rem sit. Neque non assumenda sint vero similique. Doloremque inventore excepturi beatae aliquam tenetur vel, qui quos optio reprehenderit sit eveniet vero, corporis sapiente. Inventore, quod!
//   Animi placeat voluptatem ullam repellendus excepturi tempora, harum aliquid sint a, cumque ab quos eius fugiat, ipsum repellat dolores? Accusantium, deleniti ullam expedita labore est esse voluptas obcaecati atque quae.
//   Ea vitae quam, quo molestiae sit blanditiis ipsam, iure, recusandae omnis distinctio hic consequatur soluta quos beatae. Voluptatibus a quisquam explicabo ex saepe non aut deserunt velit, omnis neque ad!
//   Quia blanditiis, est optio eveniet illo libero eos totam animi quibusdam magni dolorem minima, odit, quae accusamus porro quaerat laborum eligendi autem necessitatibus maiores. Sapiente obcaecati tempore unde illo quia.
//   Beatae assumenda est voluptatum ratione praesentium quo, amet ea itaque ad dicta fugiat nobis fuga aliquam? Cupiditate, magni porro quisquam vero sed animi possimus earum accusamus reprehenderit eum corporis adipisci.
//   Nulla iure facilis delectus consequatur suscipit soluta dolores, consectetur sunt incidunt adipisci laudantium asperiores dolorum, distinctio aperiam veniam cupiditate id voluptatum aliquid, et debitis doloribus explicabo illum architecto. Ipsam, id.
//   Inventore assumenda autem ullam quo commodi nam eligendi adipisci maxime dolor, molestias quidem animi temporibus, consequuntur dicta labore eum explicabo earum quae corrupti ipsam perspiciatis, officia voluptatem doloribus? Odio, cumque.
//   Esse, placeat doloremque assumenda recusandae veniam autem cumque possimus minus dolore vel ullam excepturi voluptatibus accusamus nobis voluptates sed explicabo enim facilis accusantium quis aliquam asperiores deserunt commodi harum. Dolore?
//   Vel ut assumenda iure consequuntur! Reiciendis, nam? Corporis, assumenda! Nobis cum dolore porro! Repudiandae amet sapiente sequi fugit ipsam non quasi provident, aspernatur natus! Obcaecati ipsum itaque cum sed eius.
//   Nemo corporis nesciunt sunt libero harum, numquam est, aut eaque reiciendis ex enim, veniam qui? Voluptate ut a fuga neque mollitia reiciendis iusto totam consectetur, recusandae, perspiciatis nemo eum dicta.
//   Voluptate, error amet repellendus sint dolore similique dolorem ex laudantium? Rerum, optio molestiae! Natus ab veritatis autem officiis obcaecati, iusto maiores! Architecto dolor consectetur delectus accusantium, ab voluptatibus neque alias.
//   Animi tenetur, aliquam voluptates voluptas debitis dolore molestiae? Voluptates nesciunt, mollitia consectetur, aspernatur ex amet repellendus vero recusandae aut modi, ut harum provident quod ea natus quis voluptas accusantium iste!
//   Quidem autem aliquid fuga, accusamus non sed ea repudiandae dolorem sint alias est consectetur corrupti quaerat necessitatibus in, quasi quae incidunt officiis quis? Minima officia nihil eaque voluptatibus illo laboriosam.
//   Excepturi, modi. Exercitationem, neque quae, nisi ipsa veritatis corporis enim voluptate optio facilis magnam pariatur cumque, dicta doloremque. Nesciunt repellendus soluta quibusdam unde eius nisi deserunt modi repellat consectetur magnam.
//   Nisi expedita iure quos ut laboriosam! Iure provident, excepturi soluta hic consectetur odio doloribus, ratione animi nesciunt sapiente beatae deleniti modi delectus voluptate eos, dolorum velit nobis aliquid non rerum.
//   Est facere deserunt quia cumque reprehenderit, repellat sit ipsum nobis itaque tenetur amet vitae quas dolore odio optio dignissimos, eveniet dicta esse. Recusandae odit atque itaque, animi tempore natus unde.
//   Quos, quia? Magnam quae molestias corrupti temporibus esse voluptas aliquid, non officiis est cum, quam possimus doloremque incidunt ratione rerum, perferendis iure iusto excepturi expedita? Facilis dolore molestias pariatur molestiae.
//   Inventore exercitationem vitae tempora earum necessitatibus? Inventore perspiciatis, vel sapiente officiis id est temporibus labore consectetur reiciendis! Placeat reiciendis velit distinctio cumque dicta qui, ex praesentium, veritatis ipsa tempore mollitia.
//   Ratione quibusdam dolorem nulla sed laborum sit voluptatum repudiandae aspernatur explicabo eveniet repellat, ipsum est exercitationem ea nihil, accusantium nisi natus necessitatibus velit aperiam quasi error dicta. Dolores, ullam eos.
//   Officiis voluptatem esse reiciendis quam, est amet sapiente nam adipisci natus blanditiis consequatur ab. Eaque architecto et fugit voluptates odit adipisci, fuga ex aspernatur alias dignissimos voluptatum molestias, voluptate provident.
//   Nulla dicta nesciunt laudantium qui placeat dolore veniam natus magni cumque labore aperiam nobis quisquam enim cupiditate sequi accusantium, consequuntur hic quidem a? Sapiente aut mollitia, fuga doloremque repellat nobis?
//   Possimus repellendus sed eius, voluptates fugiat aspernatur aut aliquam quos magni officia nihil! Nisi suscipit facere quia, enim, ratione fugiat modi excepturi ea maxime pariatur molestiae dolores ad minima rerum.
//   Minus commodi corrupti quasi officiis, praesentium soluta aliquid perferendis deleniti at temporibus architecto, non minima nostrum, in ipsam numquam fugit porro atque error necessitatibus doloribus libero hic! Corporis, inventore quibusdam.
//   Voluptatum, laboriosam libero! Quas placeat quaerat totam quasi quo! Dolorum est quisquam fugit, similique inventore maxime ex! Corporis fuga voluptates inventore vero, esse nisi eius accusantium dolore, dolores doloremque amet.
//   Suscipit voluptatibus iure blanditiis quisquam, voluptates magni fugit aut laboriosam fuga at, nemo cum officia velit sunt sed dignissimos, hic molestiae sint obcaecati error! Aperiam eaque vitae praesentium mollitia perferendis?
//   Vitae consequatur nam et odit officia ipsam cumque, aspernatur, impedit doloremque repellendus doloribus qui. Eos quaerat officiis, nulla minima iusto id animi numquam dicta expedita rem debitis laborum aspernatur consequatur.
//   Molestias, cupiditate natus, iusto illum, doloremque dolorem autem deserunt alias necessitatibus odio dignissimos earum adipisci commodi quae! Deleniti, impedit odit quasi praesentium, tenetur eligendi sunt nisi harum sequi inventore saepe.
//   Itaque excepturi minima expedita nisi rem eaque veniam explicabo unde voluptates porro alias ad, mollitia eos, pariatur nihil quis. Aut optio blanditiis nisi deserunt corporis necessitatibus voluptates suscipit fugit eum!
//   Facere velit vel dignissimos tempore id, doloribus a. Eligendi facilis nemo harum rerum debitis molestiae voluptates est deleniti officia itaque corporis nobis, commodi aspernatur mollitia repudiandae sed ullam dicta error.
//   Quos, id explicabo. Doloremque, repudiandae.
//   </div>
//   <div className="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab3">
//      Perspiciatis voluptas accusamus quasi aliquam tempore dolore temporibus eum optio laboriosam sequi, vel quam quae doloribus cupiditate possimus at, omnis dolorem repellendus assumenda fugiat aliquid?
//   Vitae, eos! Nesciunt magni vitae, fugiat vero dolorum, voluptatibus alias ipsum ut consectetur blanditiis optio similique. Perferendis tempora at est harum ipsum omnis vel, nam assumenda quasi consequatur pariatur. Molestias?
//   Quae accusantium consectetur ex? Corporis, iusto fugit? Expedita nobis nihil, officiis placeat, accusantium laboriosam magni veniam dolore repellat nulla minima, dolores optio nesciunt assumenda voluptas? Nobis iste harum molestiae ratione.
//   Tempora repellendus ea odit et optio, distinctio exercitationem in! Hic suscipit repellendus maiores dolorum temporibus officiis architecto modi unde earum, deleniti, provident quos, repellat ipsum dolorem aperiam eligendi ipsa similique!
//   Atque, ipsum! Est aut voluptas quidem praesentium animi suscipit perferendis eveniet error delectus temporibus ducimus veniam deleniti, necessitatibus iusto, nesciunt ut nostrum laudantium, molestiae molestias eligendi laboriosam laborum sunt asperiores.
//   Porro, corporis corrupti. Error quo veniam veritatis quisquam eaque cupiditate, doloremque asperiores magnam at, blanditiis impedit incidunt! Maiores soluta cum laudantium sapiente, ex, numquam nemo nulla, debitis quam nostrum expedita.
//   Architecto corporis blanditiis omnis, alias inventore quisquam laudantium eos voluptates vero dolorem excepturi accusamus, commodi minus, quis facilis aut? Cum, quasi quia quod sit repellat placeat voluptas consequatur facilis maiores!
//   Saepe voluptatem, quo, est dolores dolorum optio assumenda laboriosam eveniet explicabo, eaque ex autem ut iure facere? Vel temporibus quaerat blanditiis commodi dolor sit voluptas delectus eveniet, doloremque quam possimus.
//   Laboriosam, sequi necessitatibus! Dolorum obcaecati odit dolor sint adipisci id iusto magnam numquam ratione dolores explicabo, aperiam consectetur libero, nesciunt enim eos unde. Illum dolore deserunt animi in corrupti explicabo.
//   Odit expedita nihil dolor odio. Dolor quas laborum rerum debitis quasi nisi odio iste, officia placeat fugiat minima beatae sint cum dolorem minus maiores sequi amet sunt veniam omnis repudiandae!
//   Fugiat fuga omnis, eveniet quam repellendus ea temporibus quos vero aliquam praesentium saepe aspernatur consequatur earum eius provident esse? Assumenda reiciendis odio illo vitae quasi fuga quae eos deleniti facilis?
//   Commodi minus omnis officiis quo voluptatibus obcaecati voluptas rem sapiente porro, quam placeat quisquam, quia praesentium enim. Eaque delectus dolorum voluptatem quisquam repellendus inventore exercitationem! Quod officiis tempore voluptatibus exercitationem!
//   Laborum atque molestiae harum ea facere voluptate facilis assumenda quos sint, culpa sunt corporis eos consequatur qui, illo dolore incidunt inventore mollitia nulla voluptas. Doloribus cum eveniet voluptates? Impedit, quis.
//   Molestias alias, eligendi harum et repellendus voluptatibus numquam nesciunt vero incidunt repellat sequi ratione eveniet nobis quod modi, qui quidem veritatis nulla. Recusandae doloremque amet eveniet rem? Blanditiis, autem id.
//   Assumenda architecto quas velit veniam odit aliquam officia optio repellendus labore quia similique, asperiores in! Dolor odit, dicta exercitationem cum at suscipit consectetur provident iste excepturi, dolores, eligendi dolorem enim?
//   Dolorum praesentium velit fugiat iure fuga temporibus quisquam aliquam quibusdam optio at corporis error, exercitationem distinctio numquam, consequuntur nam, nobis quos soluta voluptates itaque vero neque. Obcaecati, illum! Facilis, reprehenderit!
//   Cum, ad. Vel nesciunt possimus error similique iusto. Labore sapiente dolorem optio ipsam? Dolorum, ipsam cumque deserunt molestias in eius tempore voluptatem quam temporibus suscipit maxime molestiae, tenetur enim amet!
//   Iste, laboriosam? Quis numquam magni molestias voluptatum quod unde nemo harum veniam ex nulla blanditiis, deserunt nam similique laboriosam veritatis, dolores corporis? Maxime accusantium aliquam reprehenderit saepe odio asperiores recusandae.
//   Vel ratione amet, voluptatem harum quos vero, molestiae illo neque aperiam magnam atque nihil voluptates quo error minima at commodi eveniet et dolor? Suscipit natus dicta, nulla at assumenda consequuntur?
//   Provident voluptatem deserunt consectetur iste, doloribus voluptates qui sit accusantium rerum dolores ad, nobis reprehenderit illo quibusdam, blanditiis assumenda odit debitis perspiciatis. Sed, non laboriosam similique aut asperiores fugit molestias.
//   Magni alias nobis voluptatum blanditiis? Minus debitis ut eligendi, repudiandae aspernatur soluta sunt perferendis, neque, delectus placeat nisi ea exercitationem fuga amet velit ipsum? Ipsam dignissimos deleniti vitae error delectus?
//   Aut omnis odit et illo esse. Accusamus culpa temporibus facilis a, sunt iste. Facilis dolorum deleniti, incidunt magni velit nam commodi illo, libero nobis voluptates quia perferendis! Autem, culpa sit.
//   Totam dolorum omnis provident possimus assumenda harum et soluta perspiciatis, inventore cumque, in architecto. Quasi quisquam quaerat sunt magni fugit aperiam dignissimos commodi. Placeat iure soluta, incidunt facere deserunt praesentium.
//   Dignissimos odit vel adipisci, similique eveniet voluptas natus dolor magnam asperiores aut! Quia eaque quaerat, mollitia obcaecati nihil ratione quas quae qui quam, ex nobis, a consequuntur nulla beatae debitis?
//   Ratione quis natus ea iusto, iure sunt totam possimus in quibusdam vitae ducimus atque, nobis accusamus neque molestias fugit odit explicabo dolores quae. Debitis, qui quasi. Dolor provident ipsum in?
//   Tenetur porro odit quasi, possimus deleniti commodi, sequi libero voluptate dignissimos vero recusandae minima distinctio eligendi doloribus alias laboriosam repudiandae. Facilis eaque, maiores amet tempora excepturi similique aliquam blanditiis fuga!
//   Voluptates, repellat laudantium illo temporibus quaerat eius labore! Mollitia perferendis delectus animi tempora quas. Possimus accusamus excepturi odio temporibus deleniti minima qui non inventore, velit dicta omnis iure, ex consectetur!
//   Quaerat mollitia hic culpa facilis recusandae ut provident vitae laboriosam voluptates iusto
//   </div>
//   <div className="tab-pane fade" id="contact4" role="tabpanel" aria-labelledby="contact-tab4">
//     Quasi beatae fugiat itaque maiores et voluptates doloremque? Consequatur quasi sapiente nemo? Numquam earum quis modi a molestiae?
//   Optio soluta corrupti neque suscipit quo nisi aliquid eius ab ad. Nulla totam consectetur cum labore? Soluta velit itaque nemo tempore enim recusandae impedit, corporis odio fugiat vitae, ipsum dicta?
//   Eligendi officiis maiores beatae vel repellendus ducimus doloremque repudiandae, itaque fugit quasi laboriosam recusandae totam sequi commodi sed ipsa nobis? Possimus esse perferendis aperiam quod mollitia eligendi, saepe aliquid itaque!
//   Incidunt hic totam qui! Saepe, eaque sunt voluptates odit, veniam debitis ex eum modi repellendus deleniti corrupti quidem nihil facere ea repudiandae, laboriosam tempora eos voluptatum minus dignissimos sint. Commodi!
//   Eum vitae animi, molestias enim nisi explicabo voluptatum. Fugiat voluptatem explicabo molestiae veritatis sed officia commodi exercitationem, quos minus labore sit libero reiciendis sapiente praesentium recusandae nihil? Necessitatibus, voluptate perferendis!
//   Expedita nesciunt facilis mollitia nihil, amet eum voluptas? Magni modi aspernatur rem nihil natus pariatur consequatur at quos architecto ab, omnis quibusdam voluptas minus tenetur porro alias aperiam suscipit temporibus.
//   Exercitationem molestiae voluptas voluptate hic atque eveniet accusantium eaque dolore numquam vitae suscipit error, ipsam veniam pariatur dolor, aspernatur expedita tempora earum. Praesentium illo assumenda hic veritatis! Ratione, est assumenda.
//   Neque asperiores odio dolorem? Consequatur itaque fuga fugiat ut dolor distinctio alias ea nam recusandae saepe eum rem ab omnis deserunt dicta soluta, aliquam iusto incidunt, culpa quo excepturi unde.
//   Cupiditate harum consectetur, mollitia enim ab asperiores et corporis, deleniti in nobis esse consequuntur excepturi minima accusamus iure illum at laboriosam? Dicta architecto debitis cum aliquid repudiandae voluptatem itaque nesciunt.
//   Dolorem sequi quas ullam sapiente blanditiis laudantium eaque voluptate, laborum tempore, culpa consequuntur quasi aperiam facilis impedit. Id obcaecati nam incidunt quaerat iure deleniti, dolorem soluta consectetur quia dolores iusto!
//   Debitis maxime molestiae animi dolorum odit. Quaerat atque quasi a, molestias commodi ducimus blanditiis nostrum similique? Earum fuga perferendis suscipit ab quaerat eum voluptatibus similique, quo, sapiente cumque, nam possimus?
//   Maxime architecto ad libero minima fugit sint molestias, magni assumenda ullam cupiditate aspernatur vero iusto ipsam! Nihil, libero suscipit amet similique quaerat, at sapiente itaque distinctio vero non voluptatibus porro!
//   Odit eligendi incidunt molestiae unde harum quisquam optio, accusantium animi quasi distinctio nihil obcaecati nulla adipisci vel asperiores nemo ex pariatur placeat voluptates deserunt consequuntur reprehenderit? Animi quo facere commodi.
//   Vel quaerat aut ipsam omnis. Voluptate cum, perferendis quisquam dicta impedit iure consequatur placeat totam deleniti eius, veniam ullam accusantium quod sapiente expedita? Neque ad perspiciatis officia hic magni? Voluptates.
//   Cumque aperiam, voluptas aliquam unde fugit expedita tempora eius cum dolorum sunt optio nostrum illo omnis nihil veritatis magni tenetur nemo sint ipsam et esse! Architecto, delectus voluptatum. Rem, necessitatibus.
//   Quibusdam, magnam? Quaerat ipsa tempora, hic eveniet a quo officia eaque commodi ullam debitis ad rem fugiat alias nam atque minus qui quas cumque veritatis! Expedita voluptas eius praesentium fuga.
//   Provident optio iure alias magnam explicabo minima ducimus quis aperiam odio, natus architecto labore sint praesentium eligendi eveniet blanditiis veritatis enim tempora nobis delectus! Perspiciatis, inventore? Atque dolores eveniet qui.
//   Enim dolor inventore impedit nam voluptatem nulla molestias numquam est, maiores, recusandae minima atque consectetur optio aut reprehenderit non voluptates voluptate cupiditate amet? Fuga esse, vitae nesciunt cum officia corrupti.
//   </div>
//   <div className="tab-pane fade" id="contact5" role="tabpanel" aria-labelledby="contact-tab5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae explicabo ullam tenetur alias aperiam omnis itaque corporis magni molestiae cumque! Iure culpa sint omnis aut dicta veniam delectus recusandae totam?
//   Odit repellendus recusandae esse cupiditate ab rem, minima cumque debitis! Necessitatibus modi totam quasi nostrum facere dolores culpa, consequuntur provident temporibus reprehenderit inventore eligendi amet nulla voluptatum, mollitia laudantium veniam.
//   Quibusdam quo atque sapiente saepe a, nemo repellat maxime aperiam facilis minima perferendis optio alias necessitatibus voluptates placeat corrupti ex dignissimos officiis officia. Est quasi similique optio eaque, praesentium quidem.
//   Exercitationem velit quo modi illo recusandae expedita iste alias voluptate! Vel dignissimos ullam inventore totam iure officia, dolores laudantium alias harum reprehenderit laborum rem impedit assumenda commodi voluptas id. Repellendus.
//   Quasi officia labore et voluptatibus commodi reiciendis nisi voluptatum quae expedita inventore. Aut, exercitationem vitae assumenda dolorum perferendis animi amet modi, consequuntur maxime, illum aperiam ex distinctio dolor possimus nam.
//   Voluptatum recusandae laboriosam magnam quaerat consequuntur ducimus aliquam amet ullam cum id exercitationem, perspiciatis tempora dolorem? Ad ut atque repellat voluptates molestiae nihil voluptatem placeat, error dolores aliquid ullam rem.
//   Laudantium ratione esse, autem cupiditate molestiae iure nobis facilis provident tempore id.  </div>
// </div>
//     </div>
//   )
// }

// export default Onglets


import React from 'react';

function Onglets({ indicateurInfo }) {
  return (
    <div>

      {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
            Producteur de l'indicateur
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
            Définitions
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
            Méthode de calcul
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="contact-tab2" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false">
            Données en entrées
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="contact-tab3" data-bs-toggle="tab" data-bs-target="#contact3" type="button" role="tab" aria-controls="contact3" aria-selected="false">
            Sources de données
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="contact-tab4" data-bs-toggle="tab" data-bs-target="#contact4" type="button" role="tab" aria-controls="contact4" aria-selected="false">
            Interprétation
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="contact-tab5" data-bs-toggle="tab" data-bs-target="#contact5" type="button" role="tab" aria-controls="contact5" aria-selected="false">
            Rang
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          {indicateurInfo.producteur}
        </div>
        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          {indicateurInfo.definition}
        </div>
        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          {indicateurInfo.methode_calcul}
        </div>
        <div className="tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
          {indicateurInfo.donnees_entrees}
        </div>
        <div className="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab3">
          {indicateurInfo.sources_donnees}
        </div>
        <div className="tab-pane fade" id="contact4" role="tabpanel" aria-labelledby="contact-tab4">
          {indicateurInfo.interpretation}
        </div>
        <div className="tab-pane fade" id="contact5" role="tabpanel" aria-labelledby="contact-tab5">
          {indicateurInfo.rang}
        </div>
      </div> */}

<div class="row">
  <div class="col-4">
    <div class="list-group" id="list-tab" role="tablist">
      <a class="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">  Producteur de l'indicateur</a>
      <a class="list-group-item list-group-item-action" id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile"> Définitions de l'indicateur</a>
      <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">Methodes de calcul</a>
      <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">Donnees en entrees</a>
      <a class="list-group-item list-group-item-action" id="list-sources-list" data-bs-toggle="list" href="#list-sources" role="tab" aria-controls="list-sources">Sources de données</a>
      <a class="list-group-item list-group-item-action" id="list-interpretation-list" data-bs-toggle="list" href="#list-interpretation" role="tab" aria-controls="list-interpretation">Interpretation</a>
      <a class="list-group-item list-group-item-action" id="list-rang-list" data-bs-toggle="list" href="#list-rang" role="tab" aria-controls="list-rang">Rang</a>
    </div>
  </div>
  <div class="col-8" style={{ background:'#f5f5f5',padding:'10px' }}>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">{indicateurInfo.producteur}</div>
      <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">{indicateurInfo.definition}</div>
      <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">{indicateurInfo.methode_calcul}</div>
      <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">{indicateurInfo.donnees_entrees}</div>
      <div class="tab-pane fade" id="list-sources" role="tabpanel" aria-labelledby="list-sources-list">{indicateurInfo.sources_donnees}</div>
      <div class="tab-pane fade" id="list-interpretation" role="tabpanel" aria-labelledby="list-interpretation-list">{indicateurInfo.interpretation}</div>
      <div class="tab-pane fade" id="list-rang" role="tabpanel" aria-labelledby="list-rang-list">
        {/* {indicateurInfo.rang} */}
        <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Pays</th>
                <th scope="col">Valeur</th>
                <th scope="col">Rang</th>
              </tr>
            </thead>
            <tbody>
            {/* <!-- Côte d'Ivoire --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/ci.png" alt="Côte d'Ivoire" class="me-2" /> Côte d'Ivoire
        </td>
        <td>15000</td>
        <td>1</td>
      </tr>
      {/* <!-- France --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/fr.png" alt="France" class="me-2" /> France
        </td>
        <td>12345</td>
        <td>2</td>
      </tr>
      {/* <!-- États-Unis --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/us.png" alt="USA" class="me-2" /> États-Unis
        </td>
        <td>9876</td>
        <td>3</td>
      </tr>
      {/* <!-- Allemagne --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/de.png" alt="Germany" class="me-2" /> Allemagne
        </td>
        <td>8765</td>
        <td>4</td>
      </tr>
      {/* <!-- Royaume-Uni --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/gb.png" alt="UK" class="me-2" /> Royaume-Uni
        </td>
        <td>7654</td>
        <td>5</td>
      </tr>
      {/* <!-- Italie --> */}
      <tr>
        <td>
          <img src="https://flagcdn.com/w20/it.png" alt="Italy" class="me-2" /> Italie
        </td>
        <td>6543</td>
        <td>6</td>
      </tr>
            </tbody>
    </table>
        </div>
    </div>
  </div>
</div>

    </div>
  );
}

export default Onglets;

