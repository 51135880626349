// import React from 'react';
// import './main.css';
// import PageTitle from './PageTitle';
// import Onglets from './Onglets';
// import CharPie from './CharPie';
// import PopChart from './PopChart';
// import Indicateurs from './Indicateurs';

// function Main({ indicateurInfo, valeurs }) {
//   const mainStyle = indicateurInfo
//     ? {}  // Pas d'image de fond si un indicateur est sélectionné
//     : {
//         backgroundImage: 'url(/images/word.png)',  // Appliquer le background seulement en l'absence de sélection
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         minHeight: '100vh',
//         padding: '20px'
//       };
//   return (
//     <main id="main" className='main' style={mainStyle}>
//       {indicateurInfo ? (
//         <>
//           <PageTitle categorie={indicateurInfo.nom} />
//           <div className="row" style={{ marginLeft: '0' , marginRight: '0',width:'100%',  display: 'flex', flexWrap: 'wrap' ,flexDirection: 'row',justifyContent: 'space-between',marginTop: '10px' , marginBottom: '10px', borderTop: '1px solid #ccc', borderBottom: '8px solid #ccc'}}>

//             <div className="col-lg-3" >

//                {/* <Indicateurs /> */}
//                <Indicateurs 
//                   title="Valeur Internationale" 
//                   value={valeurs[0].valeur} 
//                   icon="fa-chart-bar" 
//                   iconcolor="#2E86C1" 
//                   taille="col-lg-3" 
//               />
//             </div>
//             <div className="col-lg-3" >

//               {/* <Indicateurs /> */}
//             <Indicateurs 
//                   title="Valeur Nationale" 
//                   value={valeurs[0].valeur} 
//                   icon="fa-chart-bar" 
//                   iconcolor="#2E86C1" 
//                   taille="col-lg-3" 
//               />
//             </div>
//             <div className="col-lg-4" style={{ height: '80%', width: '45%'}}>

//                 {/* <CharPie series={valeurs.map(v => v.valeur)} options={{
//                 labels: valeurs.map(v => v.annee.toString())
//               }} /> */}

//             <CharPie 
//               series={valeurs.slice(0, 4).map(v => v.valeur)} 
//               options={{
//                 labels: ['Amérique du Nord', 'Europe', 'Asie-Pacifique', 'Afrique'] // Zones géographiques affectées
//               }} 
//             />


//             </div>

//           </div>
        
//           <PopChart series={[{ data: valeurs.map(v => v.valeur) }]} />
//           <Onglets indicateurInfo={indicateurInfo} />
//         </>
//       ):(
//         <div className="no-indicator-selected" style={{ textAlign: 'center', paddingTop: '50px' }}>
     
//         <p style={{ fontSize: '3rem', color: '#ffff' }}>Veuillez sélectionner un indicateur pour afficher les données</p>
//       </div>
//       )}
//     </main>
//   );
// }

// export default Main;

// import React from 'react';
// import './main.css';
// import PageTitle from './PageTitle';
// import Onglets from './Onglets';
// import CharPie from './CharPie';
// import PopChart from './PopChart';
// import Indicateurs from './Indicateurs';

// function Main({ indicateurInfo, valeurs }) {
//   const hasValues = valeurs && valeurs.length > 0; // Vérifie que `valeurs` existe et contient des éléments

//   return (
//     <main id="main" className="main">
//       {indicateurInfo ? (
//         <>
//           <PageTitle categorie={indicateurInfo.nom} />
//           <div className="row" style={{ 
//             marginLeft: '0', marginRight: '0', width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', borderTop: '1px solid #ccc', borderBottom: '8px solid #ccc'}}>
//             {hasValues ? (
//               <>
//                 <div className="col-lg-3">
//                   <Indicateurs
//                     title="Valeur Internationale"
//                     value={valeurs[0].valeur} // Accès sécurisé
//                     icon="fa-chart-bar"
//                     iconcolor="#2E86C1"
//                     taille="col-lg-3"
//                   />
//                 </div>
//                 <div className="col-lg-3">
//                   <Indicateurs
//                     title="Valeur Nationale"
//                     value={valeurs[0].valeur} // Accès sécurisé
//                     icon="fa-chart-bar"
//                     iconcolor="#2E86C1"
//                     taille="col-lg-3"
//                   />
//                 </div>
//                 <div className="col-lg-4" style={{ height: '80%', width: '45%' }}>
//                   <CharPie
//                     series={valeurs.slice(0, 4).map(v => v.valeur)} // Accès sécurisé
//                     options={{
//                       labels: ['Amérique du Nord', 'Europe', 'Asie-Pacifique', 'Afrique']
//                     }}
//                   />
//                 </div>
//               </>
//             ) : (
//               <p>Pas de données disponibles pour le moment.</p>
//             )}
//           </div>
//           {hasValues && <PopChart series={[{ data: valeurs.map(v => v.valeur) }]} />}
//           <Onglets indicateurInfo={indicateurInfo} />
//         </>
//       ) : (
//         <div className="no-indicator-selected" style={{ textAlign: 'center', paddingTop: '50px' }}>
//           <p style={{ fontSize: '1.5rem', color: '#666' }}>Veuillez sélectionner un indicateur pour afficher les données</p>
//         </div>
//       )}
//     </main>
//   );
// }

// export default Main;


import React from 'react';
import './main.css';
import PageTitle from './PageTitle';
import Onglets from './Onglets';
import CharPie from './CharPie';
import PopChart from './PopChart';
import Indicateurs from './Indicateurs';

function Main({ indicateurInfo, valeurs }) {
  const hasValues = valeurs && valeurs.length > 0; // Vérifie que `valeurs` existe et contient des éléments

  const mainStyle = !indicateurInfo ? {
    backgroundImage: 'url(/images/word2.png)',  // Appliquer le background quand aucun indicateur n'est sélectionné
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: '20px',
    overflowY: 'hidden'
  } : {};

  return (
    <main id="main" className="main" style={mainStyle}>
      {indicateurInfo ? (
        <>
          <PageTitle categorie={indicateurInfo.nom} />
          <div className="row" style={{ marginLeft: '0', marginRight: '0', width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', borderTop: '1px solid #ccc', borderBottom: '8px solid #ccc'}}>
            {hasValues ? (
              <>
                <div className="col-lg-3">
                  <Indicateurs
                    title="Valeur Internationale"
                    value={valeurs[0].valeur}
                    icon="fa-chart-bar"
                    iconcolor="#2E86C1"
                    taille="col-lg-3"
                  />
                </div>
                <div className="col-lg-3">
                  <Indicateurs
                    title="Valeur Nationale"
                    value={valeurs[0].valeur}
                    icon="fa-chart-bar"
                    iconcolor="#2E86C1"
                    taille="col-lg-3"
                  />
                </div>
                <div className="col-lg-4" style={{ height: '80%', width: '45%' }}>
                  <CharPie
                    series={valeurs.slice(0, 4).map(v => v.valeur)}
                    options={{
                      labels: ['Amérique du Nord', 'Europe', 'Asie-Pacifique', 'Afrique']
                    }}
                  />
                </div>
              </>
            ) : (
              <p>Pas de données disponibles pour le moment.</p>
            )}
          </div>
          {hasValues && <PopChart series={[{ data: valeurs.map(v => v.valeur) }]} />}
          <Onglets indicateurInfo={indicateurInfo} />
        </>
      ) : (
        <div className="no-indicator-selected" style={{ textAlign: 'center', paddingTop: '30%' }}>
          <p style={{ textShadow: '50px rgba(0, 0, 0, 0.5)', color: 'black', fontSize: '3rem',background:'#dcded2' , fontWeight:'bold'}}>Veuillez sélectionner un indicateur pour consulter les données.</p>
        </div>
      )}
    </main>
  );
}

export default Main;



