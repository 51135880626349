// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import './sideBar.css';

// // function SideBar() {
// //   const [menuData, setMenuData] = useState([]);

// //   useEffect(() => {
// //     // Appel de l'API pour récupérer la structure
// //     axios.get('https://indicateurapi.stats.ci/structure')
// //       .then(response => {
// //         setMenuData(response.data);
// //       })
// //       .catch(error => {
// //         console.error('Erreur lors de la récupération des données:', error);
// //       });
// //   }, []);

// //   return (
// //     <aside id="sidebar" className="sidebar">
// //       <ul className="sidebar-nav" id="sidebar-nav">
// //         {menuData.map(groupe => (
// //           <li key={groupe.id} className="nav-item">
// //             <button
// //               className="nav-link collapsed"
// //               data-bs-target={`#groupe-${groupe.id}`}
// //               data-bs-toggle="collapse"
// //             >
// //               <i className="bi bi-menu-button-wide"></i>
// //               <span>{groupe.nom}</span>
// //               <i className="bi bi-chevron-down ms-auto"></i>
// //             </button>
// //             <ul id={`groupe-${groupe.id}`} className="nav-content collapse" data-bs-parent="#sidebar-nav">
// //               {groupe.categories.map(categorie => (
// //                 <li key={categorie.id}>
// //                   <button
// //                     className="nav-link collapsed"
// //                     data-bs-target={`#categorie-${categorie.id}`}
// //                     data-bs-toggle="collapse"
// //                   >
// //                     <i className="bi bi-circle"></i>
// //                     <span>{categorie.nom}</span>
// //                     <i className="bi bi-chevron-down ms-auto"></i>
// //                   </button>
// //                   <ul id={`categorie-${categorie.id}`} className="nav-content collapse" data-bs-parent={`#groupe-${groupe.id}`}>
// //                     {categorie.indicateurs.map(indicateur => (
// //                       <li key={indicateur.id}>
// //                         <a href="#">
// //                           <i className="bi bi-circle"></i>
// //                           <span>{indicateur.nom}</span>
// //                         </a>
// //                       </li>
// //                     ))}
// //                   </ul>
// //                 </li>
// //               ))}
// //             </ul>
// //           </li>
// //         ))}
// //       </ul>
// //     </aside>
// //   );
// // }

// // export default SideBar;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './sideBar.css';

// function SideBar() {
//   const [menuData, setMenuData] = useState([]);

//   useEffect(() => {
//     // Appel de l'API pour récupérer la structure
//     axios.get('https://indicateurapi.stats.ci/structure')
//       .then(response => {
//         setMenuData(response.data);
//       })
//       .catch(error => {
//         console.error('Erreur lors de la récupération des données:', error);
//       });
//   }, []);

//   return (
//     <aside id="sidebar" className="sidebar">
//       <ul className="sidebar-nav" id="sidebar-nav">
//         {menuData.map(groupe => (
//           <li key={groupe.id} className="nav-item">
//             <button
//               className="nav-link collapsed"
//               data-bs-target={`#groupe-${groupe.id}`}
//               data-bs-toggle="collapse"
//             >
//               <i className="bi bi-menu-button-wide"></i>
//               <span>{groupe.nom}</span>
//               <i className="bi bi-chevron-down ms-auto"></i>
//             </button>
//             <ul id={`groupe-${groupe.id}`} className="nav-content collapse" data-bs-parent="#sidebar-nav">
//               {groupe.categories.map(categorie => (
//                 <li key={categorie.id}>
//                   <button
//                     className="nav-link collapsed"
//                     data-bs-target={`#categorie-${categorie.id}`}
//                     data-bs-toggle="collapse"
//                   >
//                     <i className="bi bi-circle"></i>
//                     <span>{categorie.nom}</span>
//                     <i className="bi bi-chevron-down ms-auto"></i>
//                   </button>
//                   <ul id={`categorie-${categorie.id}`} className="nav-content collapse" data-bs-parent={`#groupe-${groupe.id}`}>
//                     {categorie.indicateurs.map(indicateur => (
//                       <li key={indicateur.id}>
//                         <a href="#" className="nav-link">
//                           <i className="bi bi-circle"></i>
//                           <span>{indicateur.nom}</span>
//                         </a>
//                       </li>
//                     ))}
//                   </ul>
//                 </li>
//               ))}
//             </ul>
//           </li>
//         ))}
//       </ul>
//     </aside>
//   );
// }

// export default SideBar;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './sideBar.css';
// function SideBar({ onIndicateurSelect }) {
//   const [menuData, setMenuData] = useState([]);

//   useEffect(() => {
//     axios.get('https://indicateurapi.stats.ci/structure')
//       .then(response => {
//         setMenuData(response.data);
//       })
//       .catch(error => {
//         console.error('Erreur lors de la récupération des données:', error);
//       });
//   }, []);

//   return (
//     <aside id="sidebar" className="sidebar">
//       <ul className="sidebar-nav" id="sidebar-nav">
//         {menuData.map(groupe => (
//           <li key={groupe.id} className="nav-item">
//             <button
//               className="nav-link collapsed"
//               data-bs-target={`#groupe-${groupe.id}`}
//               data-bs-toggle="collapse"
//             >
//               <i className="bi bi-menu-button-wide"></i>
//               <span>{groupe.nom}</span>
//               <i className="bi bi-chevron-down ms-auto"></i>
//             </button>
//             <ul id={`groupe-${groupe.id}`} className="nav-content collapse" data-bs-parent="#sidebar-nav">
//               {groupe.categories.map(categorie => (
//                 <li key={categorie.id}>
//                   <button
//                     className="nav-link collapsed"
//                     data-bs-target={`#categorie-${categorie.id}`}
//                     data-bs-toggle="collapse"
//                   >
//                     <i className="bi bi-circle"></i>
//                     <span>{categorie.nom}</span>
//                     <i className="bi bi-chevron-down ms-auto"></i>
//                   </button>
//                   <ul id={`categorie-${categorie.id}`} className="nav-content collapse" data-bs-parent={`#groupe-${groupe.id}`}>
//                     {categorie.indicateurs.map(indicateur => (
//                       <li key={indicateur.id}>
//                         <button onClick={() => onIndicateurSelect(indicateur.id)} className="nav-link">
//                           <i className="bi bi-circle"></i>
//                           <span>{indicateur.nom}</span>
//                         </button>
//                       </li>
//                     ))}
//                   </ul>
//                 </li>
//               ))}
//             </ul>
//           </li>
//         ))}
//       </ul>
//     </aside>
//   );
// }

// export default SideBar;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './sideBar.css';

function SideBar({ onIndicateurSelect }) {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    axios.get('https://indicateurapi.stats.ci/structure')
      .then(response => {
        setMenuData(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {menuData.map(groupe => (
          <li key={groupe.id} className="nav-item">
            <a
              href="#"
              className="nav-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target={`#groupe-${groupe.id}`}
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>{groupe.nom}</span>
              <i className="bi bi-chevron-down"></i>
            </a>
            <ul id={`groupe-${groupe.id}`} className="nav-content collapse" data-bs-parent="#sidebar-nav">
              {groupe.categories.map(categorie => (
                <li key={categorie.id}>
                  <a
                    href="#"
                    className="nav-link collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#categorie-${categorie.id}`}
                  >
                    <i className="bi bi-circle"></i>
                    <span>{categorie.nom}</span>
                    <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul id={`categorie-${categorie.id}`} className="nav-content collapse" data-bs-parent={`#groupe-${groupe.id}`}>
                    {categorie.indicateurs.map(indicateur => (
                      <li key={indicateur.id}>
                        <a href="#" onClick={() => onIndicateurSelect(indicateur.id)} className="nav-link">
                          <i className="bi bi-circle"></i>
                          <span>{indicateur.nom}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;


